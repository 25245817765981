import "../styles/globals.css";
import "focus-visible";

import { ApolloProvider } from "@apollo/client/react";
import TakebackContextProvider from "../components/TakebackContext";
import { ThemeProvider } from "styled-components";
import client from "../core-modules/apollo-client";
import getConfig from "../core-modules/config";
import { theme } from "../core-modules/theme";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

function App({ Component, pageProps }) {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      TagManager.initialize({ gtmId: "GTM-KG22NH8" });
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <TakebackContextProvider>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} embedMode={true} />
        </ThemeProvider>
      </TakebackContextProvider>
    </ApolloProvider>
  );
}

export default App;
